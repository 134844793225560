@import "../styles/Variables.scss";

.navbar-container {
  width: 90%;
  height: 7rem;
  margin-top: 20px;
  display: flex;
  position: fixed; // revisar luego si funciona
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(194, 189, 189, 0.2);
  border-radius: 50px;
  border: 0.25px solid #0b0083;
  box-shadow: 0 9px 20px 4px rgba(101, 173, 255, 0.47);
  padding-left: 1%;
  padding-right: 8%;
  left: 0%;
  right: 0%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: $bp-smallest) {
    padding-right: 3%;
  }
}

.navbar-img {
  width: 15rem;
  height: 15rem;
  display: inline-block;
  position: relative;
  top: -0.4rem;
  cursor: pointer;
}

.navbar-text {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 3.2rem;
  padding: 10px;
}

.navbar-item {
  font-family: $font-Encode;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  transition: all 0.2s;
}

.navbar-link {
  color: $color-black;
  flex: 0 auto;
  align-self: center;
  justify-content: center;
  line-height: 2;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    border-radius: 2px;
    background-color: $color-black;
    position: absolute;
    bottom: -0.25rem;
    left: 50%;
    transition: width 0.4s, left 0.4s;
  }

  &:hover::before {
    width: 100%;
    left: 0;
  }
}

.navbar-button {
  color: $color-white;
  background-color: $color-black;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $color-white;
    color: $color-black;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
}
