@import "./styles/Variables.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: $font-Montserrat;
  color: $color-black;
  // background-image: $background-gradient;
  background-color: #fff;
  height: 100vh; // solo prueba
  min-height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px;

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
  @media only screen and (max-width: $bp-medium) {
    font-size: 40%;
  }
  @media only screen and (max-width: $bp-smallest) {
    font-size: 30%;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 25%;
  }
}
