// importar fuentes
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans&family=Montserrat:wght@400;500;600&display=swap");
// Colors Variables

$color-black: #000;
$color-grey: #d2d2d2;
$color-white: #fff;

// gradients

$project-box-gradient: radial-gradient(
    100% 100% at 50% 0%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.02) 100%
  ),
  linear-gradient(
    rgba(95, 159, 255, 0.7),
    rgba(126, 105, 255, 0.57),
    rgba(117, 95, 255, 0)
  ),
  linear-gradient(rgba(116, 126, 155, 1), rgba(1, 7, 26, 0)),
  linear-gradient(rgba(144, 158, 200, 1), rgba(1, 7, 26, 0.28)),
  linear-gradient(
    rgba(152, 255, 206, 0.6),
    rgba(131, 143, 255, 0.49),
    rgba(96, 178, 255, 0.2)
  );

$text-home-gradient: conic-gradient(
  from 180deg at 50% 50%,
  #d120e0 0deg,
  rgba(101, 9, 133, 0.63) 359.92798805236816deg,
  rgba(134, 27, 46, 0.06) 359.9639940261841deg,
  rgba(149, 29, 51, 0) 360deg
);

// box shadow

$project-box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.6),
  0px 3px 12px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

// font-family

$font-Encode: Encode Sans, sans-serif;
$font-Montserrat: Montserrat, sans-serif;

// media queries

// medias queris

$bp-largest: 75em; // 1200 px
$bp-large: 68.75em; // 1100 px
$bp-medium: 56.25em; // 900px
$bp-supmedium: 50em; // 800px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
$bp-phone: 25em;

// @media only screen and (max-width: $bp-medium) {
//   margin-left: 7%;
//   margin-right: 7%;
//   margin-top: 60px;
// }
