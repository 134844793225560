@import "../styles/Variables.scss";

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 100px;
  margin-bottom: 80px;
  a {
    font-size: 3rem;
    font-weight: 400;
    &:visited {
      color: $color-black;
    }
    // color: $color-black;
  }
  p {
    font-size: 1.8rem;
    font-weight: 400;
  }
}
