.skills-container {
  height: max-content;
  margin-top: 20rem;
  margin-left: 10%;
  margin-right: 10&;
}

.skills-text-container {
  p {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

.skills-text {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  h2 {
    font-size: 3.5em;
    font-weight: 400;
  }
  &-svg {
    width: 2.6rem;
  }
}
.lineaPloma {
  width: 90%;
  height: 1px; /* Altura de la línea */
  background-color: #dadada; /* Color de la línea */
  margin-right: 10%;
  margin-bottom: 20px;
  margin-top: 5px;
}

.skills-svg-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 5rem;
  grid-row-gap: 10rem;
  margin-bottom: 10rem;
  img {
    width: 8rem;
    height: 8rem;
  }
}
