@import "../styles/Variables.scss";

.section-project {
  &-text-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
  &-title {
    font-size: 3.5rem;
    font-weight: 400;
    color: inherit;
    margin-left: 10%;
    @media only screen and (max-width: $bp-medium) {
      margin-left: 7%;
    }
    @media only screen and (max-width: $bp-smallest) {
      margin-left: 5%;
    }
  }
  &-svg {
    width: 30px;
    height: 30px;
    display: inline-block;
    align-self: center;
    @media only screen and (max-width: $bp-smallest) {
      width: 20px;
      height: 20px;
    }
  }
}

.lineaPloma {
  width: 80%;
  height: 1px; /* Altura de la línea */
  background-color: #dadada; /* Color de la línea */
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  margin-top: 5px;
  @media only screen and (max-width: $bp-medium) {
    margin-left: 7%;
    margin-right: 7%;
  }
  @media only screen and (max-width: $bp-smallest) {
    margin-left: 5%;
    margin-right: 5%;
  }
}
