@import "../styles/Variables.scss";

.project-item {
  height: 47rem;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  grid-gap: 2rem;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15rem;

  @media only screen and (max-width: $bp-medium) {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 60px;
  }
  @media only screen and (max-width: $bp-smallest) {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1rem;
  }

  &-img-container {
    grid-template-columns: 1/2;
    grid-template-rows: 1/2;
    align-self: center;
    justify-self: center;
    height: 90%;
    width: 70%;
    overflow: hidden;
    border-radius: 40px;
    @media only screen and (max-width: $bp-largest) {
      width: 90%;
    }
    @media only screen and (max-width: $bp-medium) {
      width: 90%;
      height: 80%;
    }
    @media only screen and (max-width: $bp-supmedium) {
      height: 70%;
    }
    @media only screen and (max-width: $bp-small) {
      height: 60%;
      border-radius: 20px;
    }
    background-image: $project-box-gradient;
    box-shadow: $project-box-shadow;
  }

  &-text {
    grid-template-columns: 2/3;
    grid-template-rows: 1/2;
    align-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    h2 {
      font-size: 4.5rem;
      font-weight: 600;
      @media only screen and (max-width: $bp-phone) {
        font-size: 3.5rem;
      }
    }
    h3 {
      font-size: 4rem;
      font-weight: 500;
      @media only screen and (max-width: $bp-phone) {
        font-size: 3rem;
      }
    }
    p {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }
}

.btnViewMore {
  margin-top: 1rem;
  text-decoration: none;
  font-size: 1.6rem;
  color: $color-black;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  width: max-content;
  display: flex;
  &:hover {
    background-color: $color-black;
    color: $color-white;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    border: none;
    img {
      filter: invert(100%);
    }
  }
  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  // @media only screen and (max-width: $bp-phone) {
  //   font-size: 25%;
  // }
}

.img-project {
  width: 100%;
  height: 85%;
  object-fit: cover;
  padding: 1%;
  border-radius: 40px;
  @media only screen and (max-width: $bp-supmedium) {
    height: 90%;
  }
  @media only screen and (max-width: $bp-small) {
    height: 90%;
    border-radius: 20px;
  }
}

.btnViewMore {
  &-box-icon {
    width: 2rem;
    height: 2rem;
  }

  &-icon {
    width: 100%;
    height: 100%;
  }
}

.img-animation {
  display: flex;
  align-items: center;
  justify-content: center;
}
