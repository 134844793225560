@import "../styles/Variables.scss";

.footer-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5rem;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 4.4rem;
    font-weight: 400;
    display: inline-block;
  }
}
.footer-contact-button {
  margin-top: 10px;
  text-decoration: none;
  font-size: 3.5rem;
  color: $color-black;
  border-radius: 50px;
  padding: 8px 20px;
  width: max-content;
  background: #d2d2d2;
  margin-left: 5rem;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: $color-black;
    color: $color-white;
    font-size: 3.7rem;
  }
}
