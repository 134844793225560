@import "../styles/Variables.scss";

.home-container {
  height: 100vh;
  background-color: transparent;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 5%;
  padding-top: 6rem;
  margin-left: 10%;
  margin-right: 10%;
  @media only screen and (max-width: $bp-smallest) {
    padding-top: 0px;
    margin-left: 5%;
    margin-right: 5%;
    height: 50vh;
  }
}

.home {
  &-text {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: flex-end;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
  }

  &-parrafo {
    font-size: 1.8rem;
    font-weight: 400;
    grid-column: 1/2;
    grid-row: 2/3;
    align-self: flex-start;
  }

  &-img-container {
    grid-column: 2/3;
    grid-row: 1/3;
    align-self: center;
    justify-self: center;
  }

  &-img {
    width: 80%;
    height: 80%;
  }
}

.span {
  &-frontEnd {
    background-image: $text-home-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-name {
    font-weight: 600;
  }
  &-I {
    font-weight: 400;
  }
}

@media only screen and (max-width: $bp-phone) {
  .home-container {
    height: 60vh;
    display: flex;
    flex-direction: column;
  }
  .home {
    margin-bottom: 20rem;
    &-text {
      margin-top: 10em;
      align-self: flex-start;
    }
    &-img-container {
      display: flex;
      align-self: center;
      justify-content: center;
    }
    &-img {
      width: 60%;
      height: 80%;
      align-items: center;
    }
  }
}
