@import "../styles/Variables.scss";

.footer- {
  &section {
    height: max-content;
    background: rgb(179, 215, 255);
    background: linear-gradient(
      180deg,
      rgba(179, 215, 255, 1) 0%,
      rgba(215, 235, 255, 1) 37%,
      rgba(255, 255, 255, 1) 49%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 5rem;
    h3 {
      margin-top: 5rem;
      font-size: 4.3rem;
      font-weight: 600;
    }
  }
}
